{
  "name": "focus-project-web",
  "version": "1.5.3",
  "private": true,
  "source": "src/index.html",
  "browserslist": "> 0.5%, last 2 versions, not dead",
  "scripts": {
    "start": "cross-env NODE_ENV=local parcel --no-cache -p 3000",
    "build": "parcel build",
    "link:common": "npm link @lumen-developer/lumen-common-js",
    "unlink:common": "npm unlink --no-save @lumen-developer/lumen-common-js",
    "lint": "eslint .",
    "lint:fix": "eslint --fix ."
  },
  "devDependencies": {
    "@tailwindcss/forms": "^0.5.9",
    "@types/chrome": "^0.0.268",
    "@types/node": "^22.4.2",
    "@types/react": "^18.3.3",
    "@types/react-dom": "^18.3.0",
    "@types/react-router-dom": "^5.3.3",
    "@typescript-eslint/eslint-plugin": "^7.18.0",
    "@typescript-eslint/parser": "^7.18.0",
    "buffer": "^6.0.3",
    "cross-env": "^7.0.3",
    "crypto-browserify": "^3.12.0",
    "eslint": "^8.57.1",
    "eslint-config-airbnb": "^19.0.4",
    "eslint-config-airbnb-typescript": "^18.0.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-import-resolver-typescript": "^3.6.3",
    "eslint-plugin-import": "^2.31.0",
    "eslint-plugin-jsx-a11y": "^6.10.2",
    "eslint-plugin-prettier": "^5.2.1",
    "eslint-plugin-react": "^7.37.2",
    "eslint-plugin-react-hooks": "^4.6.2",
    "events": "^3.3.0",
    "parcel": "^2.12.0",
    "path-browserify": "^1.0.1",
    "postcss": "^8.4.39",
    "prettier": "^3.3.3",
    "process": "^0.11.10",
    "stream-browserify": "^3.0.0",
    "string_decoder": "^1.3.0",
    "tailwindcss": "^3.4.4",
    "tsconfig-paths": "^4.2.0",
    "vm-browserify": "^1.1.2"
  },
  "dependencies": {
    "@datadog/browser-rum": "^5.28.1",
    "@heroicons/react": "^2.1.4",
    "@heroui/react": "^2.7.4",
    "@lumen-developer/lumen-common-js": "^2.2.18",
    "@lumen-developer/rni-webcam-js": "^1.15.3",
    "@reduxjs/toolkit": "^2.2.6",
    "@rollbar/react": "^0.12.0-beta",
    "axios": "^1.7.4",
    "axios-hooks": "^5.0.2",
    "framer-motion": "^11.11.11",
    "qrcode.react": "^4.1.0",
    "react": "^18.3.1",
    "react-device-detect": "^2.2.3",
    "react-dom": "^18.3.1",
    "react-hook-form": "^7.53.2",
    "react-redux": "^9.1.2",
    "react-router-dom": "^6.24.1",
    "redux-persist": "^6.0.0",
    "rollbar": "^2.26.4",
    "uuid": "^11.0.3"
  },
  "@parcel/resolver-default": {
    "packageExports": true
  },
  "optionalDependencies": {
    "@parcel/watcher-linux-x64-glibc": "^2.4.2-alpha.0"
  }
}
