import React, { useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "@/hooks/store";
import {
  Navbar as NUINavbar,
  NavbarContent,
  NavbarItem,
  Button,
  Link,
  NavbarMenuToggle,
  Spacer,
  NavbarMenu,
  NavbarMenuItem,
} from "@heroui/react";
import { isDesktop, isMobile } from "react-device-detect";

import SettingsMenu from "./settingsMenu";

export interface INavbarItem {
  name: string;
  href: string;
  current: boolean;
  hidden?: boolean;
}

export const FocusNavbar: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const panelist = useAppSelector((state) => state.auth.panelist);
  const navigate = useNavigate();

  const navbarItems: INavbarItem[] = [
    {
      name: "Home",
      href: "/",
      current: !!useMatch("/"),
    },
    {
      name: "Rewards",
      href: "/rewards",
      current: !!useMatch("/rewards"),
    },
    {
      name: "Mobile App",
      href: "/mobile-app",
      current: !!useMatch("/mobile-app"),
      hidden: isMobile,
    },
  ];

  return (
    <NUINavbar
      maxWidth="full"
      classNames={{
        base: ["bg-secondary", "text-primary", "lg:px-9"],
      }}
      onMenuOpenChange={setIsMenuOpen}
    >
      <NavbarMenuToggle
        aria-label={isMenuOpen ? "Close menu" : "Open menu"}
        className="md:hidden"
      />
      <NavbarContent className="hidden md:flex gap-0" justify="start">
        <NavbarItem>
          <p>The Focus Project</p>
        </NavbarItem>
        <Spacer x={7} />
        {navbarItems.map(({ name, href, current, hidden }) => (
          <NavbarItem
            isActive={current}
            key={name}
            className={hidden && "hidden"}
          >
            <Button
              variant="solid"
              color="secondary"
              radius="none"
              className={`text-primary text-base font-medium pb-0 h-16 border-b-2 ${current ? "border-primary bg-secondary-800" : "border-transparent hover:bg-secondary-600"}`}
              onPress={() => {
                if (current && href === "/") {
                  window.location.href = href;
                } else {
                  navigate(href);
                }
              }}
            >
              {name}
            </Button>
          </NavbarItem>
        ))}
      </NavbarContent>
      <NavbarContent justify="end">
        {isDesktop && <NavbarItem>{panelist}</NavbarItem>}
        <NavbarItem>
          <SettingsMenu />
        </NavbarItem>
      </NavbarContent>
      <NavbarMenu className="bg-secondary">
        {navbarItems.map(({ name, href }) => (
          <NavbarMenuItem key={name}>
            <Link className="w-full" href={href} size="lg">
              {name}
            </Link>
          </NavbarMenuItem>
        ))}
      </NavbarMenu>
    </NUINavbar>
  );
};
